<template>
    <div class="table-container">
        <b-table
            :fields="tableFields"
            :items="budgetForm"
            responsive="true"
            bordered
            head-variant="light"
            sticky-header="true"
            no-border-collapse
        >
            <template #top-row="data">
                <td class="td-numbering"></td>
                <td class="td-numbering table-success">1</td>
                <td class="td-numbering table-danger">2</td>
                <td class="td-numbering table-info">3</td>
                <td class="td-numbering table-primary">4</td>
                <td class="td-numbering table-warning">5</td>
                <td class="td-numbering table-success">6</td>
                <td class="td-numbering"></td>
            </template>
            <template #head(action)>
                <div class="text-center">
                    <b-form-checkbox
                        v-model="selectAll"
                        @change="onSelectAllChanged"
                        :value="true"
                        :unchecked-value="false" />
                </div>
            </template>
            <template #head(more)>
                <div class="text-center">
                    <i
                        :title="getCommonText('del_selected')"
                        class="icon icon-close table-all-remove"
                        @click="$emit('deleteItemWithAttachedFiles', `${selectAll ? getDecText('del_all_recs') : getDecText('del_selected_recs')}`)"/>
                </div>
            </template>
            <template #cell(action)="data">
                <div class="text-center">
                    <b-form-checkbox
                        v-model="data.item.itemToDelete"
                        @input="e => { if (!e) selectAll = false; }"
                        :value="true"
                        :unchecked-value="false" />
                </div>
            </template>
            <template #cell(name)="data">
                <div
                class="column-width-100 bp-category-name">
                    <div>{{ data.item['name_' + lng] }} </div>
                    <i 
                        v-if="variantAttribute"
                        class="icon icon-pencil-edit thin-blue ml-3 pointer icon-18" 
                        @click="$emit('onEditCatName', data.item)"
                    ></i>
                </div>
            </template>
            <template #cell(unit)="data">
                <multiselect 
                    v-b-popover.hover.topright="data.item.unit_inf ? data.item.unit_inf.text : null"
                    v-if="variantAttribute"
                    class="text-left"
                    :disabled="nameIsEmpty(data.item, ['name_ru', 'name_kk'])"
                    v-model="data.item.unit_inf"
                    style="width: 270px"
                    label="text"
                    :placeholder="getDecText('enter_name')"
                    :options="listUnit"
                    :searchable="true"
                    :allow-empty="true"
                    :show-labels="false"
                    :internal-search="true"
                    @input="selectedUnitChanged(data.item)"
                    >
                    <span slot="noResult">
                        {{ getDecText('name_not_found') }}
                    </span>
                    <span slot="noOptions">
                        {{ getDecText('start_typing_name') }}
                    </span>
                </multiselect>
                <div v-else>{{ getUnitLockName(data.item.unit_inf) }}</div>
            </template>
            <template #cell(amount)="data">
                <b-form-input v-if="variantAttribute"
                                class="text-right"
                                v-model="data.item.amount"
                                :disabled="nameIsEmpty(data.item, ['name_ru', 'name_kk'])"
                                @keyup.enter.exact="$emit('keyup13')"
                                @keypress="$emit('keyPress', $event, '^[0-9]+$')"
                                @blur="$emit('inputFixed', data.item, 'amount', data.item.amount, 0, 0)">
                </b-form-input>
                <div v-else>{{ data.value }}</div>
                <p class="goods-form-val-msg" v-if="variantAttribute && (data.item.amount === null || data.item.amount === '' || data.item.amount <= 0)">{{ getCommonText("positive") }}</p>
            </template>
            <template #cell(cost)="data">
                <b-form-input v-if="variantAttribute"
                                class="text-right"
                                v-model="data.item.cost"
                                :disabled="nameIsEmpty(data.item, ['name_ru', 'name_kk'])"
                                @keyup.enter.exact="$emit('keyup13')"
                                @keypress="$emit('keyPress', $event, '^\\d*\\.?\\d{0,9}$')"
                                @blur="$emit('inputFixed', data.item, 'cost', data.item.cost, 2, 0)">
                </b-form-input>
                <div v-else>{{ data.value }}</div>
                <p class="goods-form-val-msg" v-if="variantAttribute && (data.item.cost === null || data.item.cost === '' || data.item.cost <= 0)">{{ getCommonText("positive") }}</p>
            </template>
            <template #cell()="data">
                <div class="text-right" style="min-width: 200px">{{ $n(data.value) }}</div>
            </template>
            <template #cell(files)="data">
                <span
                    class="blue pointer underline"
                    @click="$emit('onFilesClick', data.item)"
                >({{data.item.files}})</span>
            </template>
            <template #cell(more)="data">
                <div class="text-center">
                    <i 
                        :title="getCommonText('del_rec')"
                        class="icon icon-clear table-remove" 
                        v-if="variantAttribute" 
                        @click="$emit('deleteItemWithAttachedFiles', `${getCommonText('del_rec')}?`, data.item, data.index)"
                    ></i>
                </div>
            </template>
            <template #bottom-row="data">
                <td class="text-right" colspan="5">{{ getCommonText('total') }}</td>
                <td class="text-right">{{ $n(total) }}</td>
                <td colspan="2"></td>
            </template>
        </b-table>
        <div class="table-add" v-if="variantAttribute" :disabled="isLoad">
            <span @click="$emit('onAddNewCat')"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'form-418-tab1',

    props: {
        budgetForm: {type: Array, required: true},
        tableFields: {type: Array, required: true},
        getFormText: {type: Function, required: true},
        getCommonText: {type: Function, required: true},
        getDecText: {type: Function, required: true},
        getUnitLockName: {type: Function, required: true},
        lng: {type: String, required: true},
        variantAttribute: {required: true},
        nameIsEmpty: {type: Function, required: true},
        listUnit: {type: Array, required: true},
        total: {type: Number, required: true},
        isLoad: {type: Boolean, required: true},
    },

    data() {
        return {
            selectAll: false
            };
    },

    methods: {
        onSelectAllChanged(val) {
            this.$emit('setSelectAll', val)
        },

        setSelectAll(value) {
            this.selectAll = value;
        }
    },
}
</script>

<style scoped>
    .goods-form-val-msg {
        font-size: 0.5rem;
        font-style: italic;
        color: red;
    }
    .bp-category-name {
        display: flex;
        justify-content: space-between;
    }
    .bp-category-name .icon-pencil-edit {
        align-self: center;
    }
</style>